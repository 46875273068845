<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="5">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-menu mr-2"></i><strong> Infos sur l'entrepôt</strong>
            </div>
            <b-form>
              <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
              
              <c-input type="image" v-model="picture" default="DEFAULT_WAREHOUSE_PICTURE"></c-input>
              
              <c-input container-class="mb-3 required-input" label="Nom de l'entrepôt"
                       placeholder="Ex: Magasin produits"
                       v-model="name" :state="nameState">
                Veuillez saisir un nom
              </c-input>
              
              <c-input container-class="mb-3" type="textarea" label="Commentaire" placeholder="Entrez un commentaire..."
                       v-model="comment">
              </c-input>
              
              <b-input-group class="mb-3" v-if="warehouse">
                <b-button variant="link" class="px-0 text-danger" @click.prevent="deleteWarehouse">
                  Supprimer cet entrepôt
                </b-button>
                <b-modal title="Transfert du matériel avant suppression" class="modal-primary"
                         v-model="transfertWarehouseModal"
                         @hide="onTransfertWarehouseModalModalClose">
                  <b-form>
                    <description-message>
                      Les equipements, intrants, etc. de cet entrepôt seront integralement
                      transférés dans l'entrepôt que vous allez selectionner
                    </description-message>
                    <c-input container-class="mb-3 mt-3" type="select" label="Entrepôt" v-model="warehouseTransfert"
                             :options="warehousesTransfert" :state="warehouseStateTransfert">
                      <template slot="first">
                        <option :value="null">-- Sélectionnez un entrepôt --</option>
                      </template>
                      Veuillez sélectionner un entrepôt
                    </c-input>
                  </b-form>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="warning" type="submit" @click="onTransfertWareHouse"
                                    :fetching="fetchingTransfertWarehouse">
                      Supprimer et Transférer
                    </button-spinner>
                    <button-spinner variant="success" type="submit" class="px-4 ml-2" @click="onDeleteWareHouse"
                                    :fetching="fetchingDeleteWarehouse">
                      Supprimer
                    </button-spinner>
                    <b-button variant="secondary" class="ml-2" @click="this.transfertWarehouseModal = false">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
              </b-input-group>
            
            </b-form>
            
            <div id="btns" class="text-center">
              <button-spinner variant="success" type="submit" class="px-4" @click="onSubmit"
                              :fetching="fetchingCreateWarehouse">
                {{submitBtnText}}
              </button-spinner>
              <b-button letiant="secondary" @click="onCancel" class="ml-2">
                Annuler
              </b-button>
            </div>
          </b-card>
        </b-col>
        
        <b-col cols="7">
          <b-card header-tag="header" footer-tag="footer" class="map-card">
            <div slot="header">
              <i class="icon-location-pin mr-2"></i><strong> Positionnement</strong>
              <info-message>
                Vous devez spécifier l'emplacement du local que vous desirez créer.
                Pour cela, localisez votre emplacement sur la carte, cliquez sur une fois l'emplacement trouvée.
              </info-message>
            </div>
            
            <b-row>
              <b-col cols="5">
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>Lat.</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" class="form-control" placeholder="Latitude"
                                v-model="latitude" :state="latitudeState" aria-describedby="latitude-feedback"
                                id="latitude" />
                  <b-form-invalid-feedback id="latitude-feedback">
                    Veuillez entrer une latitude
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
              
              <b-col cols="5">
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>Lon.</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" class="form-control" placeholder="Longitude"
                                v-model="longitude" :state="longitudeState" aria-describedby="longitude-feedback"
                                id="longitude" />
                  <b-form-invalid-feedback id="longitude-feedback">
                    Veuillez entrer une longitude
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
              
              <b-col cols="2">
                <b-button variant="primary" class="btn btn-primary chargeBtn" @click="addFeatureManually">
                  Charger
                </b-button>
              </b-col>
            </b-row>
            
            <div id="map" :data-map="this.map" :data-vectorSource="this.vectorSource"
                 :data-sketch="this.sketch" :data-graphicsLayer="this.graphicsLayer" :data-view="this.view"></div>
            <!-- <button id="deleteFeatureButton" title="Supprimer le dessin" ref="deleteFeatureButton"><i
			   class="fa fa-trash" style="font-size: 70%"></i></button>-->
            
            <!--            <div class="delete-control ol-control ol-unselectable" ref="deleteFeatureDiv"></div>-->
            
            
            <div id="mouse-position-infos">{{mousePositionInfos}}</div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Fill, Stroke, Style, Icon, Circle as CircleStyle} from 'ol/style.js';
  import proj4 from 'proj4'
  
  import {Api, Regex, Toast, env, asset, Storage} from "../../../helpers/index";
  import {loadCss, loadModules} from "esri-loader";
  import {endGuide} from "../../../helpers";
  
  
  export default {
    name: "ExploitationCreateWarehouse",
    title: "PIA - Nouvel entrepôt de stockage",
    data() {
      return {
        picture: null,
        name: '',
        longitude: '',
        latitude: '',
        mousePositionInfos: '-- Coordonnées du curseur --',
        featureStyle: {
          'icon': new Style({
            image: new Icon({
              anchor: [0.5, 1],
              src: env('WAREHOUSE_MARKER')
            })
          }),
          'geoMarker': new Style({
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({color: 'black'}),
              stroke: new Stroke({
                color: 'white', width: 2
              })
            })
          })
        },
        comment: '',
        submitted: false,
        isValidLonLat: null,
        map: null,
        vectorSource: null,
        error: null,
        fetchingCreateWarehouse: false,
        warehouse: null,
        fetchingWarehouse: false,
        graphicsLayer: null,
        MapView: null,
        Fullscreen: null,
        view: null,
        sketch: null,
        Graphic: null,
        Point: null,
        Sketch: null,
        marker: null,
        
        transfertWarehouseModal: false,
        warehouseTransfert: null,
        warehousesTransfert: [],
        fetchingTransfertWarehouse: false,
        fetchingDeleteWarehouse: false,
        submittedTransfert: false,
        
      }
    },
    created() {
      
      Toast.guide_msg(
        "Vous devez créer une entrepôt pour pouvoir y stocker votre materiel! \n" +
        "Remplissez tous les champs pour creer votre entrepot et Valider",
        null,
        null,
        null,
        null,
        2,
        [this.exploitationId, Storage.get('guideExploitations')[this.exploitationId].guideSteps[3].openWarehouse],
        null,
        this.openWarehouseIncomes,
        null,
        null,
        endGuide
      )
      
    },
    async mounted() {
      console.log('Debut render')
      const edit = this.$route.query.edit
      if (edit && Regex.isPositiveNumber(edit)) {
        this.getWarehouse(edit)
      } else {
        console.log('Debut loadFirst')
        await this.loadFirst();
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      submitBtnText() {
        return this.warehouse ? 'Enregistrer' : 'Valider'
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      longitudeState() {
        return !this.submitted || Regex.isLongitude(parseFloat(this.longitude)) ? null : false
      },
      latitudeState() {
        return !this.submitted || Regex.isLatitude(parseFloat(this.latitude)) ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      warehouseStateTransfert() {
        return !this.submittedTransfert || this.warehouseTransfert != null ? null : false
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      asset,
      formatPolygonArea(polygon) {
        this.area = this.planarArea(polygon, 'hectares').toFixed(4) + ' ha';
        
        /*let area = getArea(polygon);
        let output;
        if (area > 10000) {
          // (Math.round(area / 1000000 * 100) / 100)
          output = +((area / 1000000) * 100).toFixed(4) +
            ' ' + 'ha';
        } else {
          // (Math.round(area * 100) / 100)
          output = (area / 10000).toFixed(4) +
            ' ' + 'ha';
        }
        this.area = output*/
        // // return output;
      },
      onChangeArea(event) {
        let geom = event.target;
        this.formatPolygonArea(geom)
      },
      async loadMod(modules) {
        try {
          let parts;
          let modulesToSend = {};
          for (let module of modules) {
            parts = module.split("/");
            let [tmp] = await loadModules([module]);
            modulesToSend[parts[parts.length - 1]] = tmp;
          }
          
          return modulesToSend;
        } catch (error) {
          console.error(error);
        }
      },
      async loadMap() {
        console.log("Appel du load Map")
        
        loadCss()
        let modules = await this.loadMod([
          "esri/WebMap",
          "esri/views/MapView",
          "esri/layers/GraphicsLayer",
          "esri/widgets/Sketch",
          "esri/widgets/Fullscreen",
          "esri/geometry/geometryEngine",
          "esri/Graphic",
          "esri/geometry/projection",
          "esri/geometry/SpatialReference",
          "esri/geometry/Point",
          "esri/symbols/PictureMarkerSymbol",
        ]);
        
        this.graphicsLayer = new modules.GraphicsLayer()
        this.MapView = modules.MapView
        this.Graphic = modules.Graphic
        this.Point = modules.Point
        this.Sketch = modules.Sketch
        this.Fullscreen = modules.Fullscreen
        
        this.marker = new modules.PictureMarkerSymbol({
          url: env('WAREHOUSE_MARKER'),
          height: '35px',
          width: '20px'
        })
        
        this.map = new modules.WebMap({
          portalItem: {
            id: "d802f08316e84c6592ef681c50178f17" //imagery with label and transportation
            // id: "86265e5a4bbb4187a59719cf134e0018" // world imagery
          },
          layers: [this.graphicsLayer]
        });
      },
      addEventOnFeature() {
        this.view.on('pointer-move', this.getMousePosition)
        // this.view.on('click', this.addMarkerFromMap)
        
        this.sketch = new this.Sketch({
          view: this.view,
          availableCreateTools: ["point"],
          layer: this.graphicsLayer
        });
        
        this.sketch.viewModel.pointSymbol = this.marker
        
        this.sketch.on("create", this.addMarkerFromMap);
        
        this.sketch.on("update", event => {
          console.log('le update')
          event.graphics[0].symbol = this.marker
          
          this.addMarkerFromMap(event)
        });
        
        let fullscreen = new this.Fullscreen({
          view: this.view
        });
        
        this.view.ui.add(this.sketch, "top-right");
        this.view.ui.add(fullscreen, "top-left");
      },
      async loadFirst() {
        console.log("Appel du load first")
        
        await this.loadMap()
        
        this.view = new this.MapView({
          container: "map",
          map: this.map,
          center: [12.354722, 7.369722],
          zoom: 6
        });
        
        this.addEventOnFeature()
        
        console.log("Fin du load first")
      },
      setLonLat4326(coordinatesEPSG3857) {
        let coordinatesEPSG4326 = proj4('EPSG:3857', 'EPSG:4326', coordinatesEPSG3857)
        this.longitude = coordinatesEPSG4326[0]
        this.latitude = coordinatesEPSG4326[1]
      },
      getMousePosition(evt) {
        let tmp = this.view.toMap({x: evt.x, y: evt.y})
        
        this.mousePositionInfos = tmp.longitude.toFixed(5) + ', ' + tmp.latitude.toFixed(5)
      },
      formFeatures(coordinate) {
        console.log('Il est bien appele', coordinate)
        let point = new this.Point({
          spatialReference: {
            wkid: coordinate.spatialReference.wkid
          },
          latitude: coordinate.latitude,
          longitude: coordinate.longitude
        })
        
        let featurePicture = new this.Graphic({
          geometry: point,
          symbol: this.marker
        })
        
        this.graphicsLayer.removeAll()
        
        this.graphicsLayer.add(featurePicture)
        
        this.longitude = coordinate.longitude.toFixed(5)
        this.latitude = coordinate.latitude.toFixed(5)
      },
      addMarkerFromMap(evt) {
        console.log('Le create: ')
        if (this.graphicsLayer.graphics.items.length > 1) {
          this.graphicsLayer.remove(this.graphicsLayer.graphics.items[0])
        }
        
        if (evt.graphic) {
          this.longitude = evt.graphic.geometry.longitude.toFixed(5)
          this.latitude = evt.graphic.geometry.latitude.toFixed(5)
        } else if (evt.graphics && evt.graphics.length) {
          this.longitude = evt.graphics[0].geometry.longitude.toFixed(5)
          this.latitude = evt.graphics[0].geometry.latitude.toFixed(5)
        }
      },
      addFeatureManually() {
        if (!Regex.isLatitude(parseFloat(this.latitude)) || !Regex.isLongitude(parseFloat(this.longitude))) {
          Toast.error('Les coordonnées entrées sont incorrectes')
          return
        }
        
        this.formFeatures({
          longitude: parseFloat(this.longitude),
          latitude: parseFloat(this.latitude),
          spatialReference: this.view.spatialReference
        })
        
        this.view.center = [parseFloat(this.longitude), parseFloat(this.latitude)]
      },
      async renderMap() {
        console.log("Appel du render")
        
        await this.loadMap()
        
        this.view = new this.MapView({
          container: "map",
          map: this.map,
          center: [parseFloat(this.warehouse.longitude), parseFloat(this.warehouse.latitude)],
          zoom: this.warehouse.zoom
        })
        
        this.addEventOnFeature()
        
        this.formFeatures({
          longitude: parseFloat(this.warehouse.longitude),
          latitude: parseFloat(this.warehouse.latitude),
          spatialReference: {wkid: 102100}
        })
      },
      getWarehouse(warehouseId) {
        this.fetchingWarehouse = true
        Api.get('/exploitation/warehouse', {
          exploitationId: this.exploitationId,
          warehouseId
        })
          .then(async res => {
            if (res.data.status === 'success' && res.data.data) {
              this.warehouse = res.data.data
              
              this.picture = this.asset(this.warehouse.picture)
              this.name = this.warehouse.name
              this.latitude = this.warehouse.latitude
              this.longitude = this.warehouse.longitude
              this.comment = this.warehouse.comment
              
              await this.renderMap()
              
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            console.log('======================== Erreur: ', error)
            
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingWarehouse = false
          })
      },
      deleteFeature() {
        this.vectorSource.clear();
        this.longitude = ''
        this.latitude = ''
      },
      redirect() {
        this.$router.push({
          name: 'ExploitationWarehouses',
          params: {id: this.exploitationId}
        })
      },
      valid() {
        return this.name.trim().length >= 3 &&
          Regex.isLongitude(parseFloat(this.longitude)) &&
          Regex.isLatitude(parseFloat(this.latitude))
      },
      onSubmit() {
        return this.warehouse ? this.updateWarehouse() : this.createWarehouse()
      },
      onCancel() {
        return this.redirect()
      },
      createWarehouse() {
        this.submitted = true;
        if (!this.valid()) return
        
        this.fetchingCreateWarehouse = true
        
        Api.post('/exploitation/warehouse/create', {
          picture: this.picture,
          name: this.name,
          exploitationId: this.exploitationId,
          comment: this.comment,
          latitude: this.latitude,
          longitude: this.longitude,
          zoom: this.view.zoom,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Entrepôt créé avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
      },
      updateWarehouse() {
        this.submitted = true;
        if (!this.valid()) return
        
        this.fetchingCreateWarehouse = true
        
        Api.post('/exploitation/warehouse/update', {
          warehouseId: this.warehouse.id,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          name: this.name,
          exploitationId: this.exploitationId,
          comment: this.comment,
          latitude: this.latitude,
          longitude: this.longitude,
          zoom: this.view.zoom,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Entrepôt mis à jour avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateWarehouse = false
          })
      },
      async deleteWarehouse() {
        const rest_list = await Api.get('/exploitation/warehouse/list', {
          exploitationId: this.exploitationId
        });
        if (rest_list.data.status === 'success' && rest_list.data.data) {
          const list_warehouses = rest_list.data.data
          if (list_warehouses.length == 1) {
            const res = confirm(`Etes-vous sûr de vouloir supprimer cet entrepôt ?`)
            if (res) {
              Api.post('/exploitation/warehouse/delete', {
                exploitationId: this.exploitationId,
                warehouseId: this.warehouse.id
              })
                .then(res => {
                  if (res.data.status === 'success' && res.data.data) {
                    Toast.success('Entrepôt supprimé avec succès !')
                    this.redirect()
                  } else {
                    this.error = res.data.error
                  }
                })
                .catch(error => {
                  this.error = {
                    message: 'Echec de la connexion au serveur'
                  }
                })
                .then(() => {
                  this.fetchingCreateWarehouse = false
                })
            }
          } else {
            this.warehousesTransfert = list_warehouses.filter(item => item.id != this.warehouse.id).map(item => ({
              value: item.id,
              text: item.name
            }))
            this.transfertWarehouseModal = true
          }
        } else {
        
        }
      },
      onTransfertWarehouseModalModalClose() {
        this.warehouseTransfert = null
      },
      onTransfertWareHouse() {
        
        this.submittedTransfert = true
        if (!this.validTransfert())
          return
        
        this.fetchingTransfertWarehouse = true
        
        Api.post('/exploitation/warehouse/transfert/all', {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouse.id,
          newWarehouseId: this.warehouseTransfert
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Elements transférés et Entrepôt supprimé avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingTransfertWarehouse = false
          })
        
      },
      onDeleteWareHouse() {
        
        this.fetchingDeleteWarehouse = true
        Api.post('/exploitation/warehouse/delete', {
          exploitationId: this.exploitationId,
          warehouseId: this.warehouse.id
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Entrepôt supprimé avec succès !')
              this.redirect()
            } else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingDeleteWarehouse = false
          })
      },
      validTransfert() {
        return this.warehouseTransfert != null
      },
      openWarehouseIncomes(id, openWarehouse = null) {
        
        if (openWarehouse == null)
          this.$router.push('/exploitation/' + this.exploitationId + '/staff/employees')
        else
          this.$router.push('/exploitation/' + this.exploitationId + '/management/warehouses/' + openWarehouse)
      },
    }
  }
</script>

<style scoped lang="stylus">
  @import "../../../../node_modules/ol/ol.css"
  #map{
    width: 100%;
    height: 480px;
    cursor: pointer;
  }
  
  .draw-control{
    top: 50px;
    right: .5em;
  }
  
  .delete-control{
    top: 50px;
    right: .5em;
  }
  
  .map-card{
    /*min-height: 600px;*/
    position: relative;
  }
  
  .chargeBtn{
    margin-right: 100%
  }
  
  #mouse-position-infos{
    position: absolute;
    bottom: 1.25rem;
    z-index: 3;
    background-color rgba(0, 0, 0, .6);
    color: white;
    font-size: 11px;
    padding: 5px 10px;
  }
</style>
